@import '@core/scss/base/bootstrap-extended/include'; // Components includes

.file-drop-zone {
  border: dashed 2px $primary;
  background-color: rgba($primary, 0.05) !important;
  min-height: 200px;
  color: $primary;
  &:after {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%237367f0' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-download'%3E%3Cpath d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4'%3E%3C/path%3E%3Cpolyline points='7 10 12 15 17 10'%3E%3C/polyline%3E%3Cline x1='12' y1='15' x2='12' y2='3'%3E%3C/line%3E%3C/svg%3E");
    font-size: 80px;
    width: 80px;
    height: 80px;
    display: block;
    margin: 0 auto;
    line-height: 1;
    z-index: 2;
    color: $primary;
    text-indent: 0;
  }
}

.file-drop-over {
  border: dotted 3px $danger;
}

.file-drop-over-another {
  border: dotted 3px $success;
}
