@import '@core/scss/core.scss';
@import './assets/scss/styles';
@import '@core/scss/angular/libs/quill-editor.component.scss';
@import '@core/scss/angular/libs/datatables.component.scss';
@import '@core/scss/angular/libs/date-time-picker.component.scss';
@import '@core/scss/angular/libs/file-uploader.component.scss';
@import '@core/scss/base/pages/app-calendar.scss';
@import '@core/scss/base/bootstrap-extended/include.scss'; // Bootstrap includes
@import '~@fullcalendar/daygrid/main.css';
@import '@core/scss/base/pages/dashboard-ecommerce.scss';
@import '@core/scss/base/plugins/charts/chart-apex.scss';
@import '@core/scss/angular/libs/toastr.component.scss';
@import '@core/scss/angular/libs/select.component.scss';
@import '@core/scss/angular/libs/flatpickr.component.scss';
@import '@core/scss/angular/libs/swiper.component.scss';

@import '@core/scss/base/pages/app-todo';
@import '@core/scss/angular/libs/quill-editor.component';
@import '@core/scss/angular/libs/select.component';
@import '@core/scss/angular/libs/drag-drop.component';
@import '@core/scss/angular/libs/flatpickr.component';


*::selection {
  background-color:
    rgb(122, 42, 122);
  color:
    #fff;
}

// Todo Application
.todo-application {
  .sidebar-toggle {
    z-index: 3;
  }

  .content-area-wrapper {
    height: calc(var(--vh, 1vh) * 100 - calc(calc(2rem * 1) + 4.45rem + 3.35rem + 1.3rem + 0rem));
  }

  .todo-checkbox {
    z-index: 1;
  }

  .todo-app-list {
    height: 100% !important;
  }

  .ql-toolbar {
    border-top: none !important;
  }

  .ql-container {
    border-bottom: none;
  }

  // .todo-task-list-wrapper {
  //   .todo-task-list {
  //     .todo-item {
  //       &:not(:first-child) {
  //         .todo-title-wrapper {
  //          // border-top: 1px solid $border-color;
  //           /* You may need to replace $border-color with the actual variable or color value */
  //         }
  //       }
  //     }
  //   }
  // }

  /* For dark layout */
  .dark-layout {
    .todo-task-list {
      .todo-item {
        &:not(:first-child) {
          .todo-title-wrapper {
            border-top: 1px solid $theme-dark-border-color !important;
          }
        }
      }
    }
  }
}

// Earning Chart Tooltip
#earnings-donut-chart {
  .apexcharts-tooltip {
    &.apexcharts-theme-dark {
      background: rgba($black, 0.8) !important;
    }
  }
}

@media only screen and (max-width: 1500px) {

  .fixed-left-select select,
  .fixed-right-select select {

    margin-right: 150px;
    /* İstediğiniz sabit genişliği buradan ayarlayabilirsiniz */
  }

  .fixed-right-select {
    margin-left: 150px;
  }

}

@media only screen and (max-width: 1000px) {

  .filter,
  ng-select {
    width: 100%;
    margin: 5px 30px 5px 10px;
  }

  .firstRow,
  #firstRow {
    display: flex;
    justify-content: flex-start;
  }

  #filtersInput {
    width: 500px;
  }

  #dateFilter {
    width: 100%;
    margin-top: 15px;
    margin-left: 20px;
  }


}

@media only screen and (max-width: 800px) {
  #filtersInput {
    width: 300px;
  }


}

@media only screen and (max-width: 660px) {
  #addTicketButton {
    margin-bottom: 5px;
  }
}


@media only screen and (max-width: 500px) {
  #éaddButton {
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 50px) {
  #applicationContent {
    display: inline-block;
  }
}


//  Main Sidebar
app-calendar-main-sidebar {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.verticalLine {
  border-left: 3px solid rgba(107, 22, 124, 0.653);
  height: 30px;
  padding: 0px;

}

#applicationContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  // background-color: #f0f0f0;
  text-align: center;
  padding: 0px;
  padding-top: 3px;
  right: 0;
}


.select-placeholder {
  display: flex;
  align-items: center;
}

.select-placeholder i {
  margin-left: 5px;
}




// Sidebar Toggle Button
.fc-sidebarToggle-button {
  background-image: url(str-replace(str-replace($menu, 'currentColor', $body-color), '#', '%23'));
  width: 24px;
  height: 24px;
  margin-right: 0.7rem !important;
  display: block;
  background-position: center;
  background-repeat: no-repeat;
}

// Hide sidebar toggle in lg screen
@include media-breakpoint-up(lg) {
  .fc-sidebarToggle-button {
    display: none !important;
  }
}

.fc {
  .fc-button-primary {
    &:not(:disabled) {
      &:active:focus {
        box-shadow: none !important;
      }
    }
  }

  .fc-button-active {
    &:focus {
      box-shadow: none !important;

      &:active {
        box-shadow: none !important;
      }
    }
  }
}

// ! Fix: fixed chrome bug
.fc-scrollgrid-section-liquid {
  height: 1px !important;
}

.datatable-scroll {
  width: auto !important;
}

@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/bootstrap.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';
@import '@core/scss/base/colors';
@import '@core/scss/base/components/include'; // Components includes

// variable declaration
$datatables-bg-header-color: $table-head-bg;
$datatables-text-color: $gray-400;
$datatables-border-color: $border-color;
$datatables-footer-text-color: $text-muted;
$datatables-dark-text-color: $theme-dark-body-color;
$datatables-dark-footer-bg-color: $theme-dark-card-bg;
$datatables-dark-border-color: $theme-dark-border-color;
$datatables-dark-header-bg-color: $theme-dark-table-header-bg;
$datatables-dark-body-bg-color: $theme-dark-table-bg;
$datatables-dark-active-bg-color: $theme-dark-card-bg;

.ngx-datatable {
  &.bootstrap {
    &.core-bootstrap {
      &.height-70-vh {
        height: 70vh !important;
      }

      .datatable-header {
        background-color: $datatables-bg-header-color;
        text-transform: uppercase;
        height: 40px !important;
        border-top: 1px solid $datatables-border-color;

        .datatable-header-cell {
          border-bottom: 0;
          padding: 0.75rem 1rem;
          font-weight: bold;
          font-size: 0.8rem;
        }

        // sort-icon positioning
        .datatable-header-cell-template-wrap {
          display: flex;
          align-items: center;
          justify-content: space-between;
          letter-spacing: 1px;
        }

        .sort-btn {
          &:before {
            font-size: 1.3rem;
          }
        }
      }

      .datatable-body-row {
        border-color: $datatables-border-color;

        &.datatable-row-even {
          background-color: initial;
        }

        &.active {
          background-color: $gray-50;
          color: $body-color;
        }

        .datatable-body-cell-label {
          .cell-line-height {
            line-height: 1.25;
          }
        }

        .datatable-body-cell {
          padding: 0.75rem 1rem;
          display: flex;
          align-items: center;
        }
      }

      .datatable-footer {
        background-color: $white;
        color: $datatables-text-color;
        overflow: hidden;
        margin-bottom: 1rem;

        .datatable-pager {
          a {
            color: $datatables-text-color;
            line-height: 20px;
          }

          ul {
            li:not(.disabled) {

              &.active,
              &.active:hover {
                vertical-align: sub;

                a {
                  background-color: $primary !important;
                  color: $white !important;
                  border-radius: 50%;
                  width: 32px;
                  height: 32px;
                  padding: 5px;
                }
              }

              &:hover {
                a {
                  background-color: transparent !important;
                  color: $primary !important;
                }
              }
            }

            li {
              margin: 2px 0px 11px 0px !important;

              &:not(.disabled):hover {
                a {
                  background-color: $white;
                  color: $datatables-text-color;
                }
              }

              &.disabled {
                a {
                  color: $text-muted;
                }
              }
            }
          }

          .pager {
            background-color: $pagination-bg;
            border-top-left-radius: 28px;
            border-bottom-left-radius: 28px;
            border-top-right-radius: 28px;
            border-bottom-right-radius: 28px;
            height: 36px;
          }
        }

        .page-count {
          color: $datatables-footer-text-color;
        }
      }

      &.header-height-50 {
        .datatable-header {
          height: 50px !important;
        }
      }

      // .datatable-body-cell {
      //    overflow: visible !important;
      // }
    }
  }
}

.preview {
  position: absolute;
  z-index: 999;
  top: 0;
  margin-left: 300px;
  left: 0;
  width: 200px;
  height: 200px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}

// media query for Small & Medium devices
@media (max-width: 768px) {
  .page-count {
    display: none;
  }

  .datatable-pager {
    text-align: center !important;
    margin: 0 !important;
  }
}

@media screen and (max-width: 800px) {
  .desktop-hidden {
    display: initial;
  }

  .mobile-hidden {
    display: none;
  }
}

@media screen and (min-width: 800px) {
  .desktop-hidden {
    display: none;
  }

  .mobile-hidden {
    display: initial;
  }
}

// dark layout style
.dark-layout {

  .datatable-header-inner,
  .datatable-header {
    background-color: $datatables-dark-header-bg-color !important;
    border-top: 1px solid $datatables-dark-border-color !important;
  }

  .ngx-datatable.bootstrap.core-bootstrap {
    .datatable-footer {
      background-color: $datatables-dark-footer-bg-color !important;

      .datatable-pager ul li.disabled a {
        color: $datatables-text-color;
      }

      .datatable-pager a {
        color: $datatables-dark-text-color;
      }
    }
  }

  .datatable-body-row {
    border-color: $datatables-dark-border-color !important;

    &.active {
      background-color: $datatables-dark-active-bg-color !important;
      color: inherit !important;
    }
  }

  .datatable-body {
    background-color: $datatables-dark-body-bg-color !important;
  }

  .pager {
    background-color: $theme-dark-pagination-bg !important;
  }
}



:host ::ng-deep :not(.cv-add-page) .ng-valid[required],
:host ::ng-deep :not(.cv-add-page) .ng-valid.required {
  border-left: 7px solid #42A948;
  /* green */
}

:host ::ng-deep :not(.cv-add-page) .ng-invalid:not(form) {
  border-left: 7px solid #a94442;
  /* red */
}



.evo-ticket-message-list {
  p {
    margin-bottom: 0;
  }
}

.merged-image {
  height: 75px;
  width: auto;
  margin-bottom: 20px;

}